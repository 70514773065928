import React, { useContext }       from 'react';
import PropTypes                   from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img                         from 'gatsby-image';

import { findT }                     from '@interness/web-core/src/components/_helpers';
import Button                        from '@interness/web-core/src/components/elements/Button/Button';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import { LanguageContext }           from '@interness/web-core/src/providers';
import routes                  from '@interness/web-core/config/routes';
import { getRandomBrandImage } from '@interness/brands-addon/src/components/_helpers';
import SplitImageSection
                               from '@interness/web-core/src/components/structure/SplitImageSection/SplitImageSection';

import * as S from './styles';

const orderTypes = (types, order) => {
  types.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
  return types
};


const Display = ({ exclude, order, buttonPrimary, inserts, layout, textOverride }) => {
  const { brands, brand_types } = useStaticQuery(query);
  const { language } = useContext(LanguageContext);
  const usedTypes = [];
  brands.nodes.forEach(({ brand }) => {
    if (!usedTypes.includes(brand.type.type) && !exclude.includes(brand.type.type)) {
      usedTypes.push(brand.type.type)
    }
  });
  const displayTypes = orderTypes(brand_types.nodes.filter(brand_type => usedTypes.includes(brand_type.type)), order);

  if (layout === 'default') {
    return (
      <S.Container>
        {displayTypes.map(displayType => {
          const translations = findT(displayType.translations, 'de');
          const image = getRandomBrandImage(brands.nodes, displayType.type, 'defaultImages');
          const override = textOverride[displayType.type];
          return (
            <S.Row key={displayType.type}>
              <div className='int-col int-textCol'>
                <h2>{translations.display_name}</h2>
                {override
                  ? <div dangerouslySetInnerHTML={{ __html: override }}/>
                  : <p>{translations.short_description}</p>
                }
                <div className='int-btn'>
                  <Button as={Link} primary={buttonPrimary} to={routes[displayType.type][language]}>Mehr
                    erfahren</Button>
                </div>
              </div>
              <div className='int-col int-imageCol'>
                <Img fluid={image.defaultImages[0].file.localFile.childImageSharp.fluid} alt={image.display_name}/>
              </div>
            </S.Row>
          )
        })}
        {inserts && inserts.map(insert => (
          <S.Row key={insert.title}>
            <div className='int-col int-textCol'>
              <h2>{insert.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: insert.description}} />
              <div className='int-btn'>
                <Button as={Link} primary={buttonPrimary} to={insert.link}>Mehr erfahren</Button>
              </div>
            </div>
            <div className='int-col int-imageCol'>
              <Img fluid={insert.image} alt={insert.title}/>
            </div>
          </S.Row>
        ))}
      </S.Container>
    )
  }

  if (layout === 'square') {
    return (
      <>
        {inserts && inserts.map((insert, i) => (
          <SplitImageSection
            heading={insert.title}
            image={insert.image}
            imagePosition={i % 2 ? 'right' : 'left'}
            key={insert.title}
          >
            <div dangerouslySetInnerHTML={{ __html: insert.description }}/>
            <Button as={Link} primary={buttonPrimary} to={insert.link}>Mehr
              erfahren</Button>
          </SplitImageSection>
        ))}
        {displayTypes.map((displayType, i) => {
          const translations = findT(displayType.translations, 'de');
          const image = getRandomBrandImage(brands.nodes, displayType.type, 'squareImages');
          const override = textOverride[displayType.type];
          return (
            <SplitImageSection
              heading={translations.display_name}
              image={image.squareImages[0].file.localFile}
              imagePosition={i % 2 ? 'left' : 'right'}
              key={displayType.type}
            >
              {override
                ? <div dangerouslySetInnerHTML={{ __html: override }}/>
                : <p>{translations.short_description}</p>
              }
              <Button as={Link} primary={buttonPrimary} to={routes[displayType.type][language]}>Mehr
                erfahren</Button>
            </SplitImageSection>
          )
        })}
      </>
    )
  }
};

Display.propTypes = {
  exclude: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.oneOf(['default', 'square']),
  textOverride: PropTypes.object,
};

Display.defaultProps = {
  exclude: [''],
  order: [''],
  layout: 'default',
  textOverride: {},
};

const query = graphql`
  query {
    brands: allDirectusBrands(filter: {brand: {status: {eq: "published"}}, id: {ne: "dummy"}}) {
      nodes {
        brand {
          display_name
          type {
            type
          }
          defaultImages: header_images {
            file {
              width
              height
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 700, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          squareImages: gallery_images_square {
            file {
              width
              height
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 700, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    brand_types: allDirectusBrandTypes(filter: {id: {ne: "dummy"}}) {
      nodes {
        type
        translations {
          display_name
          language
          short_description
          subtitle
          description
        }
      }
    }
  }
`;

export default Display;
