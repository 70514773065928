import styled from '@emotion/styled';

export const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: justify;
  overflow: hidden;
  background-color: #eeeded;
  flex-direction: ${props => props.imagePosition === 'right' ? 'row-reverse' : 'row'};
  h2 {
    text-align: left;
  }
  h4 {
    font-weight: bold;
  }
  > div {
    width: 50%;
    @media (max-width: 1100px) {
      width: 100%;
    }
  }
  .int-img {
    max-height: 800px;
    overflow: hidden;
    @media (max-width: 1100px) {
      max-height: 420px;
    }
  }
  .int-text {
    padding: 20px 80px;
    @media (max-width: 960px) {
      padding: 40px;
    }
  }
`;