import styled from '@emotion/styled';

export const ServicesDisplay = styled.div`
  width: 100%;
  .int-services-display-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    border-bottom: 1px solid lightgray;
    &:nth-child(even) {
    .int-col-text {
        order: -1;
      }
    }
    .gatsby-image-wrapper {
        border: 1px solid lightgray;
      }
    &:last-of-type {
      border-bottom: none;
    }
    .int-services-display-col {
      width: 50%;
      a {
        margin-bottom: 40px;
      }
      @media (max-width: 1120px) {
        width: 100%;
        h2 {
          margin-top: 50px !important;
        }
        &.int-col-text {
        order: 1;
        padding-right: 40px;
        padding-left: 0 !important;
      }
      }
      &.int-col-text {
        padding: 0 40px;
        h2 {
          margin-top: 0;
        }
      }
    }
    &:nth-child(even) {
     .int-col-text {
        padding-right: 40px;
        padding-left: 0;
     }
    }
  }
`;
