import React  from 'react';
import Img    from 'gatsby-image';
import Button from '@interness/web-core/src/components/elements/Button/Button';
import Link   from '@interness/web-core/src/components/elements/Link/Link';

import * as S from './styles';

const ServicesDisplay = (props) => {
  return (
    <S.ServicesDisplay>
      {props.services.map(service => {
        return (
          <div className='int-services-display-row' key={service.display_name}>
            <div className='int-services-display-col int-col-img'>
              <Img fluid={service.image} alt={service.display_name}/>
            </div>
            <div className='int-services-display-col int-col-text'>
              <h2>{service.display_name}</h2>
              <p>{service.description}</p>
              <Button as={Link} to={service.linkTo}>Mehr erfahren</Button>
            </div>
          </div>
        )
      })}
    </S.ServicesDisplay>
  )
};

export default ServicesDisplay;
