import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import Breakpoint  from 'react-socks';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Display           from '@interness/theme-twentynineteen/src/components/Display/Display';
import CTASection        from '@interness/web-core/src/components/modules/CTASection/CTASection';
import SEO               from '@interness/web-core/src/components/modules/SEO/SEO';
import { findMedia }     from '@interness/web-core/src/components/_helpers';
import ServicesDisplay   from '@interness/theme-twentynineteen/src/components/ServicesDisplay/ServicesDisplay';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';

const IndexPage = ({ data }) => {
  const services = [
    {
      display_name: 'Gutscheine',
      image: data.couponImage.media[0].file.localFile.childImageSharp.fluid,
      description: 'Gutscheine von Juwelier Spieker erfüllen Herzenswünsche. Nutzen Sie unseren kostenlosen Service mit einem Betrag Ihrer Wahl.',
      linkTo: '/gutscheine'
    },
    {
      display_name: 'Über uns',
      image: findMedia(data.indexImages.media, 'ueber-uns').childImageSharp.fluid,
      description: 'Wir möchten unseren Kunden ein ganz besonderes Einkaufserlebnis bieten.',
      linkTo: '/uber-uns'
    },
    {
      display_name: 'Goldschmiede Service',
      image: findMedia(data.indexImages.media, 'service').childImageSharp.fluid,
      description: 'Unsere Goldschmiedemeister stehen Ihnen mit ihrem Wissen und ihrem handwerklichen Können auf höchstem Niveau bei allen Anliegen rund um Ihren Schmuck zur Verfügung.',
      linkTo: '/atelier-service'
    },
    {
      display_name: 'Events & Aktuelles',
      image: findMedia(data.indexImages.media, 'events').childImageSharp.fluid,
      description: 'Regelmäßig bieten wir Ihnen Neuigkeiten und Veranstaltungen rund um die Themen Schmuck und Uhren. Erfahren Sie viel Wissenswertes, und werden Sie in unseren Kursen selbst kreativ.',
      linkTo: '/events'
    }
  ];
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data.headerImages.images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Mounted>
        <Breakpoint large up>
          <Boxgroup/>
          <Spacer/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <Spacer height={20}/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Spieker</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
            <h3>Vertrauen seit 60 Jahren</h3>
            <p>Seien Sie herzlich willkommen bei Juwelier Spieker in Hövelhof, der schönen Sennegemeinde.  <br/>Seit mehr als 60 Jahren ist Juwelier Spieker ein Begriff für traumhaften Schmuck, klassische und top aktuelle Uhren und exklusive Trauringe und Eheringe.</p>
            <p>Als Goldschmiedemeister möchte ich Ihnen Lust auf kreativen Schmuck machen. In unserer Meisterwerkstatt entstehen wundervolle Kleinode aus Gold und Silber.
            Wir bieten Ihnen einen umfangreichen Service für die Pflege und Reparaturen Ihrer Liebelingsstücke.
            Wir freuen uns auf Ihren Besuch in unserem modernen Geschäft in der Schlossstr. 15 in Hövelhof, wo wir uns in entspannter Atmosphäre ausgiebig Zeit für Ihre Wünsche nehmen möchten.</p>
            <p>Wir legen größten Wert auf individuelle und persönliche Beratung. Eine Tugend und Selbstverständlichkeit unseres Hauses.
            Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen, anzuprobieren und zu begutachten.
            In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen Schmuck als auch aktuelle Trends, moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.<br/>
            Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.</p>
            <p>Bis bald in Hövelhof.<br/>Ihre Familie Spieker.</p>
          </span>
        </section>
      </Wrapper>
      <Spacer/>
      <Wrapper>
        <section>
          <Heading tag={'h2'}>Juwelier Spieker</Heading>
          <Spacer height={20}/>
          <ServicesDisplay services={services}/>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Unsere Markenwelt</Heading>
          <Spacer height={20}/>
          <Display exclude={['wedding_rings']}/>
        </section>
        <Spacer height={20}/>
      </Wrapper>
      <CTASection/>
    </>
  )
};

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "index-header"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1400, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        indexImages: directusMediaCollection(name: {eq: "index"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        couponImage: directusMediaCollection(name: {eq: "coupon"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 274, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;

