import React from 'react';
import Img   from 'gatsby-image';

import * as S from './styles';

const SplitImageSection = ({ heading, image, imagePosition, children }) => {
  return (
    <S.Section imagePosition={imagePosition}>
      <div className='int-img'>
        <Img fluid={image.childImageSharp.fluid} alt={heading}/>
      </div>
      <div className='int-text'>
        <h2>{heading}</h2>
        {children}
      </div>
    </S.Section>
  )
}

export default SplitImageSection;
